/* eslint-disable*/
import VueStoreService from './VueStoreService';
const OwnerService = {
    async getOwners(filter,store){
        var resp = await VueStoreService.get("owner"+filter, store)
        return resp;
    },
    async saveOwner(data,store){
        var resp = await VueStoreService.post("owner",data,store)
        return resp;
    },
    async getOwnerById(owner_id,store){
        var resp = await VueStoreService.get( "owner/"+owner_id,store)
        return resp;
    },
    async updateOowner(owner_id,data,store){
        var resp = await VueStoreService.put("owner/"+owner_id,data,store)
        return resp;
    },

    async deleteOwner(owner_id,store){
        var resp = await VueStoreService.delete("owner/"+owner_id,store)
        return resp;
    },
}

export default OwnerService;