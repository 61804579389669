var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-speciality","visible":_vm.isAdd,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":"","no-close-on-backdrop":""},on:{"change":function (val) { return _vm.$emit('update:is-add', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Filtrar segun columnas")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('b-card-body',{},[_c('div',{staticClass:"d-flex"},[_c('b-col',{attrs:{"md":"6"}},[_c('h5',{staticClass:"mb-1"},[_vm._v("Visibles")]),_c('drop-list',{staticClass:"list1 d-flex flex-column",attrs:{"items":_vm.visibleFilters,"mode":"cut"},on:{"insert":_vm.onInsertVisibleFilters,"reorder":function($event){return $event.apply(_vm.visibleFilters)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('drag',{key:item.id,staticClass:"item",class:{ disabled: _vm.isVisibleDisabled },attrs:{"data":item,"disabled":_vm.isVisibleDisabled},on:{"cut":function($event){return _vm.remove(_vm.visibleFilters, item)}}},[_vm._v(" "+_vm._s(item.label)+" ")])]}},{key:"feedback",fn:function(ref){
var data = ref.data;
return [_c('div',{key:data.id,staticClass:"item feedback rounded-lg"},[_vm._v(" "+_vm._s(data.label)+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('h5',{staticClass:"mb-1"},[_vm._v("No visibles")]),_c('drop-list',{staticClass:"list2 d-flex flex-column",attrs:{"items":_vm.invisibleFilters,"mode":"cut"},on:{"insert":_vm.onInsertInvisibleFilters,"reorder":function($event){return $event.apply(_vm.invisibleFilters)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('drag',{key:item.id,staticClass:"item rounded-lg bg-light border border-light-emphasis",attrs:{"data":item},on:{"cut":function($event){return _vm.remove(_vm.invisibleFilters, item)}}},[_vm._v(" "+_vm._s(item.label)+" ")])]}},{key:"feedback",fn:function(ref){
var data = ref.data;
return [_c('div',{key:data.id,staticClass:"item feedback rounded-lg"},[_vm._v(" "+_vm._s(data.label)+" ")])]}}],null,true)})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-end p-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary"},on:{"click":hide}},[_vm._v(" Cerrar ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.sortFilters}},[_vm._v(" Aplicar ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }